import { ErrorCodes } from "atfcore-commonclasses";

// Moduli dell'applicazione
var modules = ["app.controllers", "app.directives", "app.filters", "app.services"];
modules.forEach((module) => angular.module(module, []));

// *** Aggiungo i moduli necessari all"applicazione ***
modules.push("ui.router");
modules.push("oc.lazyLoad");
modules.push("ngAnimate");
modules.push("ngCookies");
modules.push("ngResource");
modules.push("ngSanitize");
modules.push("ui.bootstrap");
modules.push("tmh.dynamicLocale");
modules.push("pascalprecht.translate");
modules.push("angularMoment");
modules.push("ngStorage");
modules.push("toaster");
modules.push("ngMaterial");
modules.push("angular-jwt");

angular.module("app", modules);

module app {

    // *** Modules need to be populated to be correctly defined, otherwise they will give warnings. null fixes this ***/
    export module controllers { null; }
    export module directives { null; }
    export module filters { null; }
    export module services { null; }

    export interface IController { }
    export interface IComponents { }
    export interface IDirective {
        restrict: string;
        link($scope: ng.IScope, element: JQuery, attrs: ng.IAttributes): any;
    }
    export interface IFilter {
        filter(input: any, ...args: any[]): any;
    }
    export interface IService { }

    /**
     * Register new controller.
     *
     * @param className
     * @param services
     */
    export function registerController(className: string, services: Array<any> = []) {
        var controller = "app.controllers." + className;
        services.push(app.controllers[className]);
        angular.module("app.controllers").controller(controller, services);
    }

    /**
     * Register new filter.
     *
     * @param className
     * @param services
     */
    export function registerFilter(className: string, services: Array<any> = []) {
        var filter = className.toLowerCase();
        services.push(() => (new app.filters[className]()).filter);
        angular.module("app.filters").filter(filter, services);
    }

    /**
     * Register new directive.
     *
     * @param className
     * @param services
     */
    export function registerDirective(className: string, services: Array<any> = []) {
        var directive = className[0].toLowerCase() + className.slice(1);
        services.push(() => new app.directives[className]());
        angular.module("app.directives").directive(directive, services);
    }

    /**
     * Register new service.
     *
     * @param className
     * @param services
     */
    export function registerService(className: string, services: Array<any> = []) {
        var service = className[0].toLowerCase() + className.slice(1);
        services.push(() => new app.services[className]());
        angular.module("app.services").factory(service, services);
    }
}

